



window.addEventListener("load", async function (event) {
    console.log("window loaded for SIDEBAR.js");
    
    //   SIDEBAR
    var navBtn = document.querySelector('.hamburger');
    navBtn.addEventListener('click', plToggle);
    // Add a click event listener to the superoverlay element
    document.querySelector('.superoverlay').addEventListener('click', function(event) {
        console.log('clickeado en nav li');
        plToggle();
    });


    
    
    
    
    // Define the plToggle function
    function plToggle(event) {
        console.log('plToggle(), TOOGLE NAVBAR', event);
        navBtn.classList.toggle('is-active');
        document.querySelector('.sidebar').classList.toggle('isOpen');
        document.querySelector('.superoverlay').classList.toggle('superoverlayisOpen');
        // var container = document.querySelector('.sidebar');
    }
    
    
});

